import { DatePipe } from '@angular/common';
import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep,isEqual,omit } from 'lodash-es';
import { filter } from 'rxjs/operators';

import { AffectationService } from 'src/app/components/vehicule/affectation/affectation.service';
import { ConducteurService } from 'src/app/components/vehicule/conducteur/conducteur.service';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { DemandeIdentificationService } from './demande-identification.service';
import { UserService } from 'src/app/components/user/user.service';
import { User } from 'src/app/domain/user/user';
import { DisplayPipe } from 'src/app/share/pipe/display/display.pipe';

@Component({
	templateUrl: './demande-identification-designation.component.html'
})
export class DemandeIdentificationDesignationComponent extends PageContentItem implements OnInit {
	/** Demande d'identification **/
	@Input() demandeIdentification: any;

	/** Mode 'Edition' **/
	@Input() isEdition: boolean = false;

	/** Enregistrement de la demande d'identification **/
	@Input() saveDemandeIdentification: (demandeIdentification: any,isPublish: boolean,close?: Function) => void;

	/** Modification de la désignation **/
	@Input() editDesignation: Function;

	/** Suppression de la désignation **/
	@Input() deleteDesignation: Function;

	/** Utilisateur connecté **/
	@Input() user: User;

	/** Liste des types de désignation **/
	public listeTypesDesignation: Array<{ code: string,_type?: string,libelle: string }>;

	/** Liste des civilités **/
	public listeTypesCivilite: Array<string>;

	/** Indicateur de prédiction de civilité **/
	public isCivilitePredicted: boolean = false;

	/** Indicateurs de présence d'une adresse ou d'un permis de conduire **/
	private mapData: { isAdresse: boolean,isPermisConduire: boolean,isCivilite: boolean,isDateNaissance: boolean,isLieuNaissance: boolean,adressePerso: any,permisConduire: any,civilite: any,dateNaissance: any,lieuNaissance: any } = {
		isAdresse: false,
		isPermisConduire: false,
		isCivilite: false,
		isDateNaissance: false,
		isLieuNaissance: false,
		adressePerso: null,
		permisConduire: null,
		civilite: null,
		dateNaissance: null,
		lieuNaissance: null
	};

	/** Réponse à l'autodésignation **/
	public autodesignation: any = {
		isConfirmed: true
	};

	/**
	 * Constructeur
	 */
	constructor(private demandeIdentificationService: DemandeIdentificationService,private affectationService: AffectationService,private conducteurService: ConducteurService,private confirmService: ConfirmService
			,private translateService: TranslateService,private userService: UserService,private datePipe: DatePipe,private displayPipe: DisplayPipe) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Copie de la demande d'identification
		this.demandeIdentification = cloneDeep(this.demandeIdentification);

		//Création de la désignation éventuelle
		this.demandeIdentification.designation = this.demandeIdentification.designation || {};

		//Récupération de la liste des types de désignation
		this.listeTypesDesignation = this.demandeIdentificationService.getListeTypesDesignation();

		//Récupération de la liste des types de civilité
		this.listeTypesCivilite = this.userService.getListeTypesCivilite();

		//Vérification de l'action externe
		if (this.user.type == 'VIRTUAL')
			//Définition du type de désignation
			this.demandeIdentification.typeDesignation = 'CONDUCTEUR';

		//Déclenchement d'un changement de type de désignation
		this.isEdition && this.onTypeDesignationChange();

		//Recherche du conducteur associé à la désignation
		this.isEdition && this.user.type != 'VIRTUAL' && this.demandeIdentification.typeDesignation == 'CONDUCTEUR' && this.demandeIdentification.designation.user && this.searchPermisAndAdresseConducteur(this.demandeIdentification.designation.user.idUser,true);
	}

	/**
	 * Affichage de l'historique des affectations
	 */
	showListeAffectations() {
		//Affichage de la liste des affectations du véhicule
		this.affectationService.showListeAffectations(this.demandeIdentification.vehicule).subscribe({
			next: affectation => {
				//Vérification de l'affectation sélectionnée
				if (affectation) {
					//Mise à jour de la désignation
					this.demandeIdentification.designation.user = affectation.user;

					//Déclenchement d'un changement de conducteur
					this.onConducteurChange();
				}
			}
		});
	}

	/**
	 * Affichage du permis de conduire
	 */
	showPermisConduire() {
		//Affichage du permis de conduire
		this.conducteurService.showPermisConduire(this.demandeIdentification.designation?.permisConduire || {},!this.isEdition).pipe(filter(p => !!p)).subscribe(permisConduire => {
			//Mise à jour du permis de conduire
			this.demandeIdentification.designation.permisConduire = permisConduire;
		});
	}

	/**
	 * Interception du changement de type de désignation
	 */
	onTypeDesignationChange() {
		//Vérification du type de désignation
		switch (this.demandeIdentification.typeDesignation) {
		case 'CONDUCTEUR':
			//Retrait de la société
			this.demandeIdentification.designation.societe = null;

			//Vérification de l'absence d'utilisateur
			if (!this.demandeIdentification.designation?.user) {
				//Vérification de la présence d'un conducteur théorique
				if (this.demandeIdentification.extension?.userTheorique) {
					//Définition du collaborateur affecté au véhicule lors de l'infraction
					this.demandeIdentification.designation.user = this.demandeIdentification.extension.userTheorique;

					//Recherche d'un permis de conduire et de l'adresse
					this.searchPermisAndAdresseConducteur(this.demandeIdentification.extension.userTheorique.idUser);
				}
			}

			//Vérification de la désignation et de la notification
			if (!this.demandeIdentification.designation.notification)
				//Initialisation de la demande de notification
				this.demandeIdentification.designation.notification = true;

			//Vérification de la désignation et de la transmission du mail collaborateur
			if (!this.demandeIdentification.designation.transmissionMail)
				//Initialisation de la demande de transmission du mail collaborateur
				this.demandeIdentification.designation.transmissionMail = true;
			break;
		case 'SOCIETE':
			//Retrait du conducteur
			this.demandeIdentification.designation.user = null;
			this.demandeIdentification.designation.adresse = null;
			this.demandeIdentification.designation.permisConduire = null;
			this.demandeIdentification.designation.civilite = null;
			this.demandeIdentification.designation.dateNaissance = null;
			this.demandeIdentification.designation.lieuNaissance = null;

			//Retrait de la prédiction de civilité
			this.isCivilitePredicted = false;
			break;
		default:
			//Retrait du conducteur et de la société
			this.demandeIdentification.designation.user = null;
			this.demandeIdentification.designation.adresse = null;
			this.demandeIdentification.designation.permisConduire = null;
			this.demandeIdentification.designation.societe = null;
			this.demandeIdentification.designation.civilite = null;
			this.demandeIdentification.designation.dateNaissance = null;
			this.demandeIdentification.designation.lieuNaissance = null;

			//Retrait de la prédiction de civilité
			this.isCivilitePredicted = false;
			break;
		}
	}

	/**
	 * Interception du changement de conducteur
	 */
	onConducteurChange() {
		//Recherche d'un permis de conduire et de l'adresse
		this.demandeIdentification.designation.user && this.searchPermisAndAdresseConducteur(this.demandeIdentification.designation.user.idUser);
	}

	/**
	 * Recherche du permis de conduire et de l'adresse du conducteur
	 */
	searchPermisAndAdresseConducteur(idUser: number,isWithoutUpdate: boolean = false) {
		//Recherche du permis de conduire et de l'adresse
		this.conducteurService.findPermisConducteur(idUser).subscribe({
			next: result => {
				//Vérification du retour
				if (result?.data) {
					//Vérification de la mise à jour
					if (!isWithoutUpdate)
						//Définition du permis de conduire
						this.demandeIdentification.designation.permisConduire = result.data.permisConduire;

					//Vérification du permis de conduire
					if (this.demandeIdentification.designation.permisConduire) {
						//Vérification de la mise à jour
						if (!isWithoutUpdate)
							//Suppression de l'identifiant
							this.demandeIdentification.designation.permisConduire.idPermis = null;

						//Mémorisation du permis de conduire
						this.mapData.permisConduire = result.data.permisConduire;

						//Indicateur de présence du permis de conduire
						this.mapData.isPermisConduire = true;
					} else {
						//Ré-initialisation du permis de conduire
						this.mapData.permisConduire = null;

						//Indicateur de présence du permis de conduire
						this.mapData.isPermisConduire = false;
					}

					//Vérification du conducteur
					if (result.data.user?.email && this.demandeIdentification.designation.user)
						//Définition du mail du conducteur
						this.demandeIdentification.designation.user.email = result.data.user.email;

					//Vérification de l'adresse du conducteur
					if (result.data.user?.userDescription?.adressePerso) {
						//Vérification de la mise à jour
						if (!isWithoutUpdate)
							//Mise à jour de l'adresse
							this.demandeIdentification.designation.adresse = result.data.user.userDescription.adressePerso;

						//Vérification de la mise à jour
						if (!isWithoutUpdate)
							//Suppression de l'identifiant de l'adresse
							delete this.demandeIdentification.designation.adresse.idAdresse;

						//Mémorisation de l'adresse
						this.mapData.adressePerso = result.data.user.userDescription.adressePerso;

						//Indicateur de présence de l'adresse
						this.mapData.isAdresse = true;
					} else {
						//Vérification de la mise à jour
						if (!isWithoutUpdate)
							//Suppression de l'adresse
							this.demandeIdentification.designation.adresse = null;

						//Ré-initialisation de l'adresse
						this.mapData.adressePerso = null;

						//Indicateur de présence de l'adresse
						this.mapData.isAdresse = false;
					}

					//Vérification de la civilité
					if (result.data.user?.civilite) {
						//Vérification de la mise à jour
						if (!isWithoutUpdate)
							//Mise à jour de la civilité
							this.demandeIdentification.designation.civilite	= result.data.user.civilite;

						//Mémorisation de la civilité
						this.mapData.civilite = result.data.user.civilite;

						//Indicateur de présence de la civilité
						this.mapData.isCivilite = true;
						this.isCivilitePredicted = false;
					} else {
						//Vérification de la prédiction de la civilité
						if (result.data.user?.civilitePrediction) {
							//Vérification de la mise à jour
							if (!isWithoutUpdate)
								//Suppression de la civilité
								this.demandeIdentification.designation.civilite = result.data.user?.civilitePrediction.civilite;

							//Initialisation de la civilité prédite
							this.mapData.civilite = null;

							//Indicateur de prédiction de la civilité
							this.isCivilitePredicted = true;

							//Indicateur de présence de la civilité
							this.mapData.isCivilite = true;
						} else {
							//Vérification de la mise à jour
							if (!isWithoutUpdate)
								//Suppression de la civilité
								this.demandeIdentification.designation.civilite = null;

							//Ré-initialisation de la civilité
							this.mapData.civilite = null;

							//Indicateur de présence de la civilité
							this.mapData.isCivilite = false;

							//Indicateur de prédiction de la civilité
							this.isCivilitePredicted = false;
						}
					}

					//Vérification de la date de naissance
					if (result.data.user?.userDescription?.dateNaissance) {
						//Vérification de la mise à jour
						if (!isWithoutUpdate)
							//Mise à jour de la date de naissance
							this.demandeIdentification.designation.dateNaissance = result.data.user.userDescription.dateNaissance;

						//Mémorisation de la date de naissance
						this.mapData.dateNaissance = result.data.user.userDescription.dateNaissance;

						//Indicateur de présence de la date de naissance
						this.mapData.isDateNaissance = true;
					} else {
						//Vérification de la mise à jour
						if (!isWithoutUpdate)
							//Suppression de la date de naissance
							this.demandeIdentification.designation.dateNaissance = null;

						//Ré-initialisation de la date de naissance
						this.mapData.dateNaissance = null;

						//Indicateur de présence de la date de naissance
						this.mapData.isDateNaissance = false;
					}

					//Vérification du lieu de naissance
					if (result.data.user?.userDescription?.lieuNaissance) {
						//Vérification de la mise à jour
						if (!isWithoutUpdate)
							//Mise à jour du lieu de naissance
							this.demandeIdentification.designation.lieuNaissance = result.data.user.userDescription.lieuNaissance;

						//Vérification de la mise à jour
						if (!isWithoutUpdate)
							//Suppression de l'identifiant du lieu de naissance
							delete this.demandeIdentification.designation.lieuNaissance.idAdresse;

						//Mémorisation du lieu de naissance
						this.mapData.lieuNaissance = result.data.user.userDescription.lieuNaissance;

						//Indicateur de présence du lieu de naissance
						this.mapData.isLieuNaissance = true;
					} else {
						//Vérification de la mise à jour
						if (!isWithoutUpdate)
							//Suppression du lieu de naissance
							this.demandeIdentification.designation.lieuNaissance = null;

						//Ré-initialisation du lieu de naissance
						this.mapData.lieuNaissance = null;

						//Indicateur de présence du lieu de naissance
						this.mapData.isLieuNaissance = false;
					}
				}
			}
		});
	}

	/**
	 * Enregistrement de la désignation
	 */
	public saveDesignation() {
		let typeDesignation: { code: string,_type?: string,libelle: string };
		let doSave: (isPublish: boolean) => void
		let messageConfirmation: string;

		//Recherche du type de désignation
		typeDesignation = this.listeTypesDesignation.find(t => t.code == this.demandeIdentification.typeDesignation);

		//Vérification du type de désignation
		if (typeDesignation._type)
			//Définition du type
			this.demandeIdentification.designation._type = typeDesignation._type;

		//Définition de la méthode d'enregistrement
		doSave = (isPublish: boolean) => {
			//Vérification de la nécessité de retirer la désignation
			if (this.demandeIdentification.typeDesignation == 'ENVOI_PAPIER' || this.user.type == 'VIRTUAL' && !this.autodesignation.isConfirmed) {
				//Retrait de la désignation
				delete this.demandeIdentification.designation;

				//Vériication de l'action externe
				if (this.user.type == 'VIRTUAL')
					//Suppression du type de désignation
					this.demandeIdentification.typeDesignation = null;
			}

			//Enregistrement de la demande d'identification
			this.saveDemandeIdentification(this.demandeIdentification,isPublish,this.close);
		}

		//Vérification du mode de désignation
		if (this.demandeIdentification.typeDesignation == 'CONDUCTEUR' && this.user.type != 'VIRTUAL') {
			//Comparaison des adresses
			this.mapData.isAdresse = isEqual(omit(this.demandeIdentification.designation.adresse,['idAdresse']),omit(this.mapData.adressePerso,['idAdresse']));

			//Comparaison des permis de conduire
			this.mapData.isPermisConduire = isEqual(omit(this.demandeIdentification.designation.permisConduire,['idPermis','user']),omit(this.mapData.permisConduire,['idPermis','user']));

			//Comparaison de la civilité
			this.mapData.isCivilite = isEqual(this.demandeIdentification.designation.civilite,this.mapData.civilite);

			//Comparaison de la date de naissance
			this.mapData.isDateNaissance = isEqual(this.demandeIdentification.designation.dateNaissance,this.mapData.dateNaissance);

			//Comparaison du lieu de naissance
			this.mapData.isLieuNaissance = isEqual(omit(this.demandeIdentification.designation.lieuNaissance,['idAdresse']),omit(this.mapData.lieuNaissance,['idAdresse']));
		}

		//Vérification de l'absence d'adresse ou du permis de conduire (hors autodésignation)
		if (this.demandeIdentification.typeDesignation == 'CONDUCTEUR' && this.user.type != 'VIRTUAL' && (!this.mapData.isAdresse || !this.mapData.isPermisConduire || !this.mapData.isCivilite || !this.mapData.isDateNaissance || !this.mapData.isLieuNaissance)) {
			//Affichage du message de demande de mise à jour des informations du conducteur
			this.confirmService.showConfirm(this.translateService.instant('demandeIdentification.designation.confirmation.title'),{
				withTitle: true,
				buttonType: 'YES_NO',
				actionColor: 'primary',
				alerteInfo: `
					<p></p>
					<div>
						<span>${this.translateService.instant('demandeIdentification.designation.confirmation.message')}</span>
						<ul class="p-t-40">
							${!this.mapData.isAdresse && this.demandeIdentification.designation.adresse ? `<li><b>${this.translateService.instant('demandeIdentification.designation.confirmation.adressePersonnelle')}</b>&#160;:&#160;<span>${this.demandeIdentification.designation.adresse.label}</span></li>` : ''}
							${!this.mapData.isPermisConduire && this.demandeIdentification.designation.permisConduire ? `<li><b>${this.translateService.instant('demandeIdentification.designation.confirmation.permisConduire')}</b>&#160;:&#160;<span>${this.demandeIdentification.designation.permisConduire.reference}</span></li>` : ''}
							${!this.mapData.isCivilite && this.demandeIdentification.designation.civilite ? `<li><b>${this.translateService.instant('demandeIdentification.designation.confirmation.civilite')}</b>&#160;:&#160;<span>${this.translateService.instant('user.genre.'+this.demandeIdentification.designation.civilite)}</span></li>` : ''}
							${!this.mapData.isDateNaissance && this.demandeIdentification.designation.dateNaissance ? `<li><b>${this.translateService.instant('demandeIdentification.designation.confirmation.dateNaissance')}</b>&#160;:&#160;<span>${this.datePipe.transform(this.demandeIdentification.designation.dateNaissance,'shortDate')}</span></li>` : ''}
							${!this.mapData.isLieuNaissance && this.demandeIdentification.designation.lieuNaissance ? `<li><b>${this.translateService.instant('demandeIdentification.designation.confirmation.lieuNaissance')}</b>&#160;:&#160;<span>${this.demandeIdentification.designation.lieuNaissance.label}</span></li>` : ''}
						</ul>
					</div>
				`
			}).subscribe({
				next: isConfirmed => {
					//Réalisation de l'enregistrement
					doSave(isConfirmed);
				}
			});
		} else if (this.user.type == 'VIRTUAL' && this.autodesignation.isConfirmed) {
			//Définition du message de confirmation
			messageConfirmation = `
				<p>${this.translateService.instant('demandeIdentification.designation.autodesignation.confirmation.rappelInfraction',{
					date: this.datePipe.transform(this.demandeIdentification.dateInfraction,'short'),
					vehicule: this.displayPipe.transform(this.demandeIdentification.vehicule,'vehicule'),
					typeInfraction: this.demandeIdentification.typeInfraction.libelle
				})}</p>
				<p>${this.translateService.instant('demandeIdentification.designation.autodesignation.confirmation.modeEnvoi',{
					modeEnvoi: this.translateService.instant('demandeIdentification.modeEnvoiAvis.' + (this.demandeIdentification.designation?.transmissionMail ? 'email' : 'courrier'))
				})}</p>
			`;

			//Affichage du message de confirmation de l'autodésignation
			this.confirmService.showConfirm(messageConfirmation,{ actionColor: 'primary' }).pipe(
				filter(isConfirmed => !!isConfirmed)
			).subscribe({
				next: () => {
					//Réalisation de l'enregistrement
					doSave(false);
				}
			});
		} else {
			//Enregistrement de la demande
			doSave(false);
		}
	}
}