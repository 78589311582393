<form #importForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="tile-active-show collapse" [collapse]="!connecteurExecution?.isDisplayed">
		<div class="tile-sub">
			<import-content #importContent [connecteurExecution]="connecteurExecution" source="IMPORT" (update)="onContentUpdate($event)" (close)="onContentClose($event)"></import-content>
		</div>
		<div class="tile-footer">
			<button mat-button [formDetectChanges]="{ doAction: close,isDirty: importForm.dirty }">
				<span translate>actions.fermer</span>
			</button>
			<button mat-raised-button color="primary" (click)="importContent.deleteImport();" *ngIf="connecteurExecution.idExecution && rightService.isRoot() && (null | right:'suppression') && connecteurExecution.connecteur?.type?.typeConnecteur == 'INTERFACE'">
				<span translate>actions.supprimer</span>
			</button>
		</div>
	</div>
</form>