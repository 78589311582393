import { AfterContentChecked,ChangeDetectorRef,Component,Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { LayoutService } from 'src/app/share/layout/layout.service';

@Component({
	selector: 'import-resume-execution',
	templateUrl: './import-resume-execution.component.html'
})
export class ImportResumeExecutionComponent implements AfterContentChecked {
	/** Exécution de l'import **/
	@Input() connecteurExecution: any;

	/** Résultat **/
	result: { connecteurExecution: any };

	/**
	 * Constructeur
	 */
	constructor(private changeDetectorRef: ChangeDetectorRef,public bsModalRef: BsModalRef<ImportResumeExecutionComponent>,private layoutService: LayoutService) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Enregistrement de l'exécution
	 */
	public saveImportResumeExecution() {
		//Définition du résultat
		this.result = {
			connecteurExecution: this.connecteurExecution
		};

		//Fermeture de la modal
		this.bsModalRef.hide();
	}

	/**
	 * Fermeture de la modal
	 */
	public close() {
		//Fermeture de la modal
		this.bsModalRef.hide();
	}

	/**
	 * Redirection vers l'exécution d'import
	 */
	public goToImport(connecteurExecution: any = this.connecteurExecution) {
		//Navigation vers l'exécution d'import
		this.layoutService.goToByState('listeImports',{
			savedSearch: {
				listeSelectedFilters: [{
					clef: 'idExecution',
					typeComparaison: TypeComparaison.EQUAL,
					type: TypeFilter.LONG,
					valeur: connecteurExecution.idExecution,
					title: `${connecteurExecution?.importTemplate?.libelle} (${connecteurExecution.idExecution})`,
					displayedValeur: ''
				}]
			},
			reloadOnSameUrl: true,
			isOpenFirstItem: true
		});

		//Fermeture de la modal
		this.bsModalRef.hide();
	}
}