import { Component,OnInit,ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { combineLatest,Observable,Subject } from 'rxjs';
import { filter,first,switchMap } from 'rxjs/operators';

import { AppState } from 'src/app/domain/appstate';
import { AttachmentEntity,LinksOwningEntity } from 'src/app/domain/attachment/attachment';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { Options } from 'src/app/domain/common/complex-page/options';
import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { User } from 'src/app/domain/user/user';
import { TypeStatut } from 'src/app/domain/vehicule/infraction/demande-identification';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { NotificationService } from 'src/app/share/components/notification/notification.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { DisplayPipe } from 'src/app/share/pipe/display/display.pipe';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { DemandeIdentificationEditComponent } from './demande-identification-edit.component';
import { DemandeIdentificationService } from './demande-identification.service';
import { AttachmentService } from 'src/app/share/components/attachment/attachment.service';
import { DemandeIdentificationDesignationComponent } from './demande-identification-designation.component';

@Component({
	selector: 'demande-identification',
	templateUrl: './demande-identification.component.html'
})
export class DemandeIdentificationComponent implements OnInit,IEntity,AttachmentEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Demande d'identification **/
	public demandeIdentification: any;

	/** Type d'attachment **/
	readonly typeAttachment: TypeAttachment = TypeAttachment.DEMANDE_IDENTIFICATION;

	/** Utilisateur connecté **/
	user: User;

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/**
	 * Constructeur
	 */
	constructor(private store: Store<AppState>,private activatedRoute: ActivatedRoute,private demandeIdentificationService: DemandeIdentificationService,private translateService: TranslateService,private displayPipe: DisplayPipe,private rightService: RightService
			,private notificationService: NotificationService,private confirmService: ConfirmService,private toastrService: ToastrService,private pageContentService: PageContentService,private datePipe: DatePipe,private attachmentService: AttachmentService) {

	}

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.sgi.DemandeIdentification';

	/**
	 * Récupération de l'identifiant de l'objet
	 */
	getIdObject: () => number = () => this.demandeIdentification?.idIdentification || 0;

	/**
	 * Récupération de l'entité portant les pièces jointes
	 */
	getOwningEntity: () => LinksOwningEntity = () => this.demandeIdentification;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Sélection de l'utilisateur connecté et vérification de la présence de notifications
		combineLatest([this.store.select<User>(state => state.session.user).pipe(filter(user => !!user))
			,this.notificationService.isNotificationAvailableFor(this.getClassName())
		]).pipe(first()).subscribe(([user,hasNotification]) => {
			//Définition de l'utilisateur connecté
			this.user = user;

			//Chargement des données
			this.loadData(this.activatedRoute.snapshot.params.idIdentification).subscribe({
				complete: () => {
					//Initialisation de la page complexe
					this.options = {
						listeFields: [{
							libelle: 'demandeIdentification.vehicule',
							key: 'vehicule',
							type: 'DISPLAY',
							format: 'vehicule'
						},{
							libelle: 'demandeIdentification.dateInfraction.long',
							key: 'dateInfraction',
							type: 'DATE',
							format: 'datetime'
						},this.user.type != 'VIRTUAL' && {
							libelle: 'demandeIdentification.dateLimiteReponse',
							key: 'dateLimite',
							type: 'DATE'
						},this.user.type != 'VIRTUAL' && {
							libelle: 'demandeIdentification.conducteurTheorique',
							key: () => this.demandeIdentification?.extension?.userTheorique ? this.displayPipe.transform(this.demandeIdentification.extension.userTheorique,'user') : this.translateService.instant(this.demandeIdentification?.extension?.vehiculeImmobilise ? 'demandeIdentification.vehiculeImmobilise' : 'common.aucun')
						}].filter(f => !!f),
						listeActions: this.user.type != 'VIRTUAL' ? [{
							libelle: 'actions.notifier',
							doAction: () => this.sendNotification(),
							isVisible: () => hasNotification
						},{
							libelle: 'actions.modifier',
							doAction: () => this.editDemandeIdentification(),
							isVisible: () => this.rightService.hasRight(null,'creation'),
							type: TypeAction.EDITION
						},{
							libelle: 'actions.consulter',
							doAction: () => this.editDemandeIdentification(),
							isVisible: () => !this.rightService.hasRight(null,'creation'),
							type: TypeAction.CONSULTATION
						},{
							libelle: 'demandeIdentification.actions.changerStatut',
							doAction: () => this.updateStatut(),
							isVisible: () => this.rightService.hasRight(null,'creation') && user?.originalUser?.typeRevendeur == 'ROOT' && this.demandeIdentification?.statut == 'TRANSMIS'
						}] : [],
						listeAlertes: [{
							icon: 'directions_car',
							title: this.translateService.instant('demandeIdentification.alerte.nouvelleDemande.title',{ date: this.datePipe.transform(this.demandeIdentification?.dateLimite,'shortDate') }),
							message: this.translateService.instant('demandeIdentification.alerte.nouvelleDemande.message'),
							isVisible: () => this.user.type != 'VIRTUAL' && this.demandeIdentification?.statut == 'NOUVELLE_DEMANDE' && !this.pageContentService.isOpened(),
							doAction: () => this.editDesignation(),
							isActionVisible: () => this.rightService.hasRight(null,'creation')
						},{
							icon: 'info',
							level: 'INFO',
							title: this.translateService.instant('demandeIdentification.alerte.reponseIntegree.title'),
							message: this.translateService.instant('demandeIdentification.alerte.reponseIntegree.message',{ date: this.datePipe.transform(this.demandeIdentification?.dateIntegration,'short') }),
							isVisible: () => this.user.type != 'VIRTUAL' && this.demandeIdentification?.statut == 'INTEGREE' && !this.pageContentService.isOpened()
						},{
							icon: 'info',
							level: 'INFO',
							title: this.translateService.instant('demandeIdentification.alerte.aucuneReponse.title'),
							message: this.translateService.instant('demandeIdentification.alerte.aucuneReponse.message',{ date: this.datePipe.transform(this.demandeIdentification?.dateStatut,'shortDate') }),
							isVisible: () => this.user.type != 'VIRTUAL' && this.demandeIdentification?.statut == 'ENVOI_PAPIER' && this.demandeIdentification?.dateStatut && !this.pageContentService.isOpened()
						},{
							icon: 'info',
							level: 'INFO',
							title: this.translateService.instant('demandeIdentification.alerte.reponseAnonymisee.title'),
							message: this.translateService.instant('demandeIdentification.alerte.reponseAnonymisee.message',{ date: this.datePipe.transform(this.demandeIdentification?.designation?.dateAnonymisation,'shortDate') }),
							isVisible: () => this.user.type != 'VIRTUAL' && this.demandeIdentification?.designation?.dateAnonymisation && !this.pageContentService.isOpened()
						},{
							icon: 'info',
							level: 'INFO',
							title: this.translateService.instant('demandeIdentification.alerte.designationEffectuee.title'),
							message: '',
							isVisible: () => this.user.type == 'VIRTUAL' && !this.checkAutodesignation()
						},{
							icon: 'warning',
							level: 'INFO',
							title: this.translateService.instant('demandeIdentification.alerte.autodesignationCaduque.title'),
							message: this.translateService.instant('demandeIdentification.alerte.autodesignationCaduque.message'),
							isVisible: () => this.user.type == 'VIRTUAL' && this.checkAutodesignation() && this.user.externalAction.idObjet != this.demandeIdentification.extension?.userTheorique?.idUser
						}],
						listeElements: [{
							type: 'DESIGNATION',
							component: DemandeIdentificationDesignationComponent,
							retrieveComponentData: () => ({
								demandeIdentification: this.demandeIdentification,
								editDesignation: this.editDesignation.bind(this),
								deleteDesignation: this.deleteDesignation.bind(this),
								user: this.user
							}),
							isTabHidden: true,
							isVisible: () => this.user.type != 'VIRTUAL' && (this.demandeIdentification?.designation || this.demandeIdentification?.typeDesignation == 'ENVOI_PAPIER')
						}],
						customStyle: {
							elements: {
								body: ' '
							}
						},
						isFormCustomization: this.user.type != 'VIRTUAL'
					};

					//Vérification que l'autodésignation est autorisée
					if (this.checkAutodesignation() && this.user.externalAction.idObjet == this.demandeIdentification.extension?.userTheorique?.idUser)
						//Création d'une désignation
						setTimeout(() => this.editDesignation());
				}
			});
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(idIdentification: number): Observable<any> {
		let subject: Subject<any> = new Subject<any>();

		//Chargement de la demande d'identification
		this.demandeIdentificationService.loadDemandeIdentification(idIdentification).pipe(first()).subscribe({
			next: result => {
				//Vérification du chargement
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Mise à jour de la demande d'identification
					this.demandeIdentification = result.data.demandeIdentification;

					//Initialisation du compteur de pièces-jointes
					this.user.type != 'VIRTUAL' && this.attachmentService.initAttachments(this);
				}
			},
			complete: () => subject.complete()
		});

		return subject;
	}

	/**
	 * Edition de la demande d'identification
	 */
	private editDemandeIdentification() {
		//Ouverture du composant d'édition
		this.pageContentService.open(DemandeIdentificationEditComponent,{
			data: {
				demandeIdentification: this.demandeIdentification,
				saveDemandeIdentification: this.saveDemandeIdentification.bind(this)
			}
		}).subscribe({
			next: demandeIdentification => {
				//Vérification des données
				if (demandeIdentification) {
					//Mise à jour de la demande d'identification
					this.demandeIdentification = demandeIdentification;
				}
			}
		});
	}

	/**
	 * Modification de la désignation
	 */
	private editDesignation() {
		//Ouverture du composant d'édition
		this.pageContentService.open(DemandeIdentificationDesignationComponent,{
			data: {
				demandeIdentification: this.demandeIdentification,
				saveDemandeIdentification: this.saveDemandeIdentification.bind(this),
				isEdition: true,
				user: this.user
			}
		},'sub').subscribe({
			next: () => {
				//Sélection de l'onglet
				this.complexPage.setSelectedElementByType('DESIGNATION',true);
			}
		});
	}

	/**
	 * Changement du statut de la demande d'identification
	 */
	private updateStatut() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('demandeIdentification.confirmation.changementStatut'),{ actionColor: 'primary' }).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.demandeIdentificationService.updateStatutForDemandeIdentification(this.demandeIdentification.idIdentification,TypeStatut.A_TRANSMETTRE))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

				//Mise à jour de la demande d'identification
				Object.assign(this.demandeIdentification,result.data.demandeIdentification);
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
		});
	}

	/**
	 * Envoi d'une notification
	 */
	private sendNotification() {
		//Notification
		this.notificationService.showSelectionMailForEntite(this.getClassName(),this.getIdObject(),{
			getOwningEntity: () => this.demandeIdentification,
			typeAttachment: TypeAttachment.DEMANDE_IDENTIFICATION
		});
	}

	/**
	 * Enregistrement de la demande d'identification
	 */
	public saveDemandeIdentification(demandeIdentification: any,isPublish: boolean,close?: Function) {
		//Enregistrement de la demande d'identification
		this.demandeIdentificationService.saveDemandeIdentification(demandeIdentification,isPublish).subscribe({
			next: result => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Définition de la demande d'identification
					this.demandeIdentification = result.data.demande;

					//Fermeture de l'édition
					close?.(this.demandeIdentification);
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}

	/**
	 * Suppression de la désignation
	 */
	public deleteDesignation() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.demandeIdentificationService.saveDemandeIdentification(Object.assign(this.demandeIdentification,{
				statut: TypeStatut.NOUVELLE_DEMANDE,
				typeDesignation: null,
				designation: null,
				typeReponseConducteur: 'NON_DEFINI'
			})))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Mise à jour de la demande d'identification
				this.demandeIdentification = result.data.demande;
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}

	/**
	 * Vérification de la possibilité d'effectuer une autodésignation
	 */
	private checkAutodesignation(): boolean {
		//Retour de la possibilité d'effectuer une autodésignation
		return this.user?.type == 'VIRTUAL' && this.user.externalAction?.idEntite && this.demandeIdentification?.typeReponseConducteur == 'NON_DEFINI' && this.demandeIdentification.statut == 'NOUVELLE_DEMANDE' && !this.demandeIdentification.designation?.idDesignation;
	}
}