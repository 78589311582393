import { HttpClient } from '@angular/common/http';
import { EventEmitter,Injectable,Injector } from '@angular/core';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { v1 as uuidv1 } from 'uuid' ;

import { Result } from 'src/app/domain/common/http/result';
import { ProgressConfig,ProgressConfigOptions } from 'src/app/domain/progress/progress';
import { ProgressErreurComponent } from './progress-erreur.component';
import { environment } from 'src/environments/environment';
import { ProgressModalComponent } from './progress-modal.component';

@Injectable({
	providedIn: 'root'
})
export class ProgressService {
	/** Indicateur de progression en cours **/
	private hasProgress: boolean = false;

	/** Liste des progressions **/
	private listeProgress: Array<ProgressConfig> = [];

	/** Instance de la modal */
	public bsModalRef: BsModalRef <ProgressModalComponent>;

	/** Mise à jour du statut de la progression **/
	public onProgressUpdate: EventEmitter<void> = new EventEmitter();

	/**
	 * Constructeur
	 */
	constructor(private injector: Injector,private bsModalService: BsModalService,private http: HttpClient) {

	}

	/**
	 * Récupération de l'indicateur de visibilité de la progression
	 */
	isProgressVisible(): boolean {
		//Récupération de l'indicateur de visibilité de la progression
		return this.hasProgress;
	}

	/**
	 * Définition de visibilité des progressions
	 */
	setProgressVisible(hasProgress: boolean) {
		//Définition de visibilité des progressions
		this.hasProgress = hasProgress;
	}

	/**
	 * Récupération de la liste des progressions
	 */
	getListeProgress(): Array<any> {
		//Récupération de la liste des progressions
		return this.listeProgress;
	}

	/**
	 * Suppression d'une progression
	 */
	deleteProgress(idxProgress: number | ProgressConfig) {
		//Vérification du type de paramètre
		if (typeof idxProgress !== 'number')
			//Recherche de l'index de la configuration de la progression
			idxProgress = this.listeProgress.indexOf(idxProgress);

		//Suppression de la progression
		this.listeProgress.splice(idxProgress,1);

		//Définition de l'indicateur de visibilité de la progression
		this.setProgressVisible(this.listeProgress.length > 0);

		//Notification d'un changement
		this.onProgressUpdate.emit();
	}

	/**
	 * Recherche des erreurs de la session
	 */
	private getListeErreursForSession(idSession: any): Observable<Result> {
		//Récupération de la liste des erreurs pour la session
		return this.http.post<Result>(`${environment.baseUrl}/controller/Messaging/retrieveListeErreurs/${idSession}`,null);
	}

	/**
	 * Affichage de la modale des erreurs de la session
	 */
	showProgressDetail(progress) {
		//Récupération des erreurs de la session
		progress.idSession && this.getListeErreursForSession(progress.idSession).pipe(first()).subscribe({
			next: result => {
				//Vérification de la présence d'erreurs
				if (result.data.listeErreurs?.length > 0) {
					//Ouverture de la popup des erreurs de progression
					this.bsModalService.show(ProgressErreurComponent,{
						initialState: {
							progress,
							listeErreurs: result.data.listeErreurs
						},
						class: 'modal-lg'
					});
				}
			}
		});
	}

	/**
	 * Initialisation d'une progression
	 */
	init(progressConfig: ProgressConfig): string {
		let reference: string;

		//Génération d'une référence unique
		reference = uuidv1();

		//Définition de la progression
		progressConfig = {
			...progressConfig,
			reference,
			nbProcessed: 0,
			nbIgnored: 0,
			listeMessages: [],
			options: {
				...(progressConfig.options || {}),
				canDismiss: progressConfig.options?.canDismiss !== false
			}
		};

		//Ouverture de la modal
		this.bsModalRef = this.bsModalService.show(ProgressModalComponent,{
			initialState: {
				progressConfig
			},
			class: 'modal-lg',
			backdrop: progressConfig.options.canDismiss ? true : 'static'
		});

		//Ajout en tête de liste
		this.listeProgress.unshift(progressConfig);

		//Définition de l'indicateur de visibilité de la progression
		this.setProgressVisible(this.listeProgress.length > 0);

		//Notification de l'initialisation du composant
		this.onProgressUpdate.emit();

		//Retour de la référence de la progression
		return reference;
	}

	/**
	 * Rafraichissement d'une progression
	 */
	refreshProgress(reference: string,nbProcessed: number,nbIgnored: number,nbTotal: number,options?: ProgressConfigOptions) {
		let progressConfig: ProgressConfig;

		//Recherche de la progression
		progressConfig = this.getProgressConfig(reference);

		//Vérification de la présence de l'information de progression
		if (progressConfig) {
			//Mise à jour du nombre d'éléments traités
			progressConfig.nbProcessed = nbProcessed;
			progressConfig.nbIgnored = nbIgnored;
			progressConfig.nbTotal = nbTotal;

			//Vérification de la présence d'une session dans les options
			if (options?.idSession)
				//Mise à jour de l'identifiant de session
				progressConfig.options.idSession = options.idSession;

			//Vérification de la présence d'un message
			if (options?.message)
				//Ajout du message à la liste
				progressConfig.listeMessages.push(options.message);

			//Vérification de la progression
			if (((progressConfig.nbIgnored || 0) + progressConfig.nbProcessed) >= progressConfig.nbTotal)
				//Fermeture de la modal
				this.bsModalRef?.hide();

				//Notification d'un changement
				this.onProgressUpdate.emit();
		}
	}

	/**
	 * Mise à jour d'une progression
	 */
	updateProgress(reference: string,options: ProgressConfigOptions) {
		let progressConfig: ProgressConfig;

		//Recherche de la progression
		progressConfig = this.getProgressConfig(reference);

		//Vérification de la présence de l'information de progression
		if (progressConfig) {
			//Mise à jour des options
			progressConfig.options.idSession = options?.idSession;

			//Vérification de la présence d'un message
			if (options?.message)
				//Ajout d'un message à la liste
				progressConfig.listeMessages.push(options.message);

			//Vérification de la présence de l'indicateur de fermeture possible de la pop up
			if (typeof options?.canDismiss != 'undefined')
				//Mise à jour de l'indicateur
				progressConfig.options.canDismiss = options.canDismiss;

			//Vérification de la présence de l'indicateur de fermeture possible de la pop up
			if (typeof options?.canClose != 'undefined')
				//Mise à jour de l'indicateur
				progressConfig.options.canClose = options.canClose;

			//Notification d'un changement
			this.onProgressUpdate.emit();
		}
	}

	/**
	 * Récupération d'une configuration de progression
	 */
	getProgressConfig(reference: string): ProgressConfig {
		//Retour de la progression
		return this.listeProgress.find(progress => progress.reference === reference);
	}
}