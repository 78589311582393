import { Component,Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ProgressConfig } from 'src/app/domain/progress/progress';
import { ProgressService } from './progress.service';

@Component({
	selector: 'progress-modal',
	templateUrl: './progress-modal.component.html'
})
export class ProgressModalComponent {
	/** Configuration de la progression **/
	@Input() progressConfig: ProgressConfig;

	/**
	 * Constructeur
	 */
	constructor(private bsModalRef: BsModalRef<ProgressModalComponent>,private progressService: ProgressService) { }

	/**
	 * Fermeture de la modal
	 */
	close(isDeleteProgress: boolean = false) {
		//Vérification de la suppression de la progression
		if (isDeleteProgress)
			//Suppression de la progression
			this.progressService.deleteProgress(this.progressConfig);

		//Fermeture de la modal
		this.bsModalRef.hide();
	}
}
