import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions,createEffect,ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first,map,withLatestFrom } from 'rxjs/operators';

import { Action } from 'src/app/domain/action';
import { AppState } from 'src/app/domain/appstate';
import { Result } from 'src/app/domain/common/http/result';
import { Session } from 'src/app/domain/security/session';
import { User } from 'src/app/domain/user/user';
import { CHECK_USER_ARTICLE,LOAD_SESSION_USER,SESSION_FULFILLED,UPDATE_APPLI_URL,UPDATE_CONFIDENTIALITE_URL,UPDATE_SUPPORT_URL,UPDATE_USER } from 'src/app/reducers/session';
import { GlobalSearchService } from 'src/app/share/layout/global-search/global-search.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class LayoutEffects {
	/**
	 * Constructeur
	 */
	constructor(private actions$: Actions,private http: HttpClient,private store: Store<AppState>,private globalSearchService: GlobalSearchService) {

	}

	/**
	 * Mise à jour d'une session
	 */
	loadUser$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(SESSION_FULFILLED),
		map((action: Action) => {
			let session: Session;

			//Lecture de la session
			session = action.payload as Session;

			//Vérification de la connexion
			if (session?.isLogged && session?.xAuthToken && !session?.isRefresh) {
				//Chargement de l'utilisateur
				this.loadUser();
			} else if (!session?.isRefresh) {
				//Retrait de l'utilisateur
				this.store.dispatch({
					type: UPDATE_USER,
					payload: null
				});
			}

			//Retour de l'action
			return action;
		})
	),{ dispatch: false });

	/**
	 * Initialisation de l'utilisateur
	 */
	initUser$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(LOAD_SESSION_USER),
		withLatestFrom(this.store.select(s => s.session)),
		map(([action,session]) => {
			//Vérification de l'absence d'utilisateur
			if (session?.isLogged && session?.xAuthToken)
				//Chargement de l'utilisateur
				this.loadUser();

			//Retour de l'action
			return action;
		})
	),{ dispatch: false });

	/**
	 * Chargement de l'utilisateur
	 */
	private loadUser() {
		//Chargement de l'utilisateur
		this.http.post<Result>(`${environment.baseUrl}/controller/Dashboard/getUser`,null).pipe(first()).subscribe({
			next: result => {
				let user: User;

				//Récupération de l'utilisateur
				user = result.data.user;

				//Stockage de l'utilisateur
				this.store.dispatch({
					type: UPDATE_USER,
					payload: {
						...user,
						listeDroits: result.data.listeDroits || null,
						listeEntitesForValideur: result.data.listeEntitesForValideur,
						listeSocietes: result.data.listeSocietes,
						devise: result.data.devise,
						unite: result.data.unite,
						uniteVolume: result.data.uniteVolume,
						pays: result.data.pays,
						timezone: result.data.timezone,
						consentementNeeded: result.data.consentementNeeded
					}
				});

				//Vérification de la lecture des articles par l'utilisateur
				this.store.dispatch({
					type: CHECK_USER_ARTICLE,
					payload: {
						consentementNeeded: result.data.consentementNeeded,
						onboardingNeeded: result.data.onboardingNeeded,
						alertesNeeded: result.data.alertesNeeded,
						nouveautesNeeded: result.data.nouveautesNeeded
					}
				});

				//Mise à jour de l'URL de l'application
				this.store.dispatch({
					type: UPDATE_APPLI_URL,
					payload: result.data.appliUrl
				});

				//Mise à jour de l'URL de la confidentialité
				this.store.dispatch({
					type: UPDATE_CONFIDENTIALITE_URL,
					payload: result.data.confidentialiteUrl
				});

				//Mise à jour de l'URL du support
				this.store.dispatch({
					type: UPDATE_SUPPORT_URL,
					payload: result.data.supportUrl
				});

				//Initialisation des mappings pour la recherche globale
				this.globalSearchService.init();
			}
		});
	}
}