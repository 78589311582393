/**
 * Interface représentant un type d'entité
 */
export interface TypeEntite {
	/** Données **/
	[key: string]: TypeEntiteValue | TypeEntite
}

/**
 * Interface représentant une valeur de type d'entité
 */
export interface TypeEntiteValue {
	/** Données **/
	type: string,
	rootKey?: string,
	autocomplete?: string,
	autocompleteFilter?: string,
	listeOperators?: Array<string>
	listeGroupements?: Array<string>,
	listeValues?: Array<string>
}

/**
 * Mapping des entités
 */
export const entites: TypeEntite = {
	'boolean': {
		type: 'BOOLEAN',
		listeOperators: ['EQUAL','NOT_EQUAL']
	},
	'double': {
		type: 'NUMBER',
		listeOperators: ['EQUAL','NOT_EQUAL','GREATER','LESS','IS_NULL','IS_NOT_NULL']
	},
	'java': {
		'lang': {
			'String': {
				type: 'CHAINE',
				listeOperators: ['EQUAL','NOT_EQUAL','CONTAINS','NOT_CONTAINS','STARTS_WITH','NOT_STARTS_WITH','IS_NULL','IS_NOT_NULL']
			},
			'Double': {
				type: 'NUMBER',
				listeOperators: ['EQUAL','NOT_EQUAL','GREATER','GREATER_OR_EQUAL','LESS','LESS_OR_EQUAL','IS_NULL','IS_NOT_NULL']
			},
			'Integer': {
				type: 'NUMBER',
				listeOperators: ['EQUAL','NOT_EQUAL','GREATER','GREATER_OR_EQUAL','LESS','LESS_OR_EQUAL','IS_NULL','IS_NOT_NULL']
			},
			'Long': {
				type: 'NUMBER',
				listeOperators: ['EQUAL','NOT_EQUAL','GREATER','LESS','IS_NULL','IS_NOT_NULL']
			},
			'Boolean': {
				type: 'BOOLEAN',
				listeOperators: ['EQUAL','NOT_EQUAL']
			}
		},
		'util': {
			'Date': {
				type: 'DATE',
				listeOperators: ['EQUAL','NOT_EQUAL','GREATER','GREATER_OR_NULL','GREATER_OR_EQUAL','LESS','LESS_OR_NULL','LESS_OR_EQUAL','IS_NULL','IS_NOT_NULL','ROLLING_PERIOD'],
				listeGroupements: ['AUCUN','MOIS','TRIMESTRE','ANNEE','JOUR','JOUR_SEMAINE']
			}
		}
	},
	'com': {
		'notilus': {
			'data': {
				'TypeSourceSaisie': {
					type: 'ENUM',
					rootKey: 'typeSourceSaisie',
					listeValues: ['SAISIE','IMPORT']
				},
				'TypeOrigineSaisie': {
					type: 'ENUM',
					rootKey: 'typeOrigineSaisie',
					listeValues: ['WEB','MOBILE']
				},
				'aide': {
					'TypeArticle': {
						type: 'ENUM',
						rootKey: 'article.type',
						listeValues: ['STANDARD','CGU','NOUVEAUTES','ALERTE','ONBOARDING']
					}
				},
				'geographie': {
					'Pays': {
						type: 'AUTOCOMPLETE',
						autocomplete: 'pays'
					}
				},
				'user': {
					'Categorie': {
						type: 'AUTOCOMPLETE',
						autocomplete: 'userCategorie'
					},
					'User': {
						type: 'AUTOCOMPLETE',
						autocomplete: 'user'
					},
					'TypeCivilite': {
						type: 'ENUM',
						rootKey: 'user.genre',
						listeValues: ['MADAME','MONSIEUR']
					},
					'TypeUser': {
						type: 'ENUM',
						rootKey: 'user.typeUser',
						listeValues: ['UTILISATEUR','ADMINISTRATEUR','UTILISATEUR_AUTO','COMPTE_SERVICE','VIRTUAL']
					}
				},
				'tenant': {
					'Tenant': {
						type: 'AUTOCOMPLETE',
						autocomplete: 'tenant'
					}
				},
				'vehicule': {
					'Affectation': {
						type: 'AUTOCOMPLETE',
						autocomplete: 'affectation'
					},
					'VehiculeGenre': {
						type: 'AUTOCOMPLETE',
						autocomplete: 'genre'
					},
					'VehiculeCarburant': {
						type: 'AUTOCOMPLETE',
						autocomplete: 'carburant'
					},
					'VehiculeCompteur': {
						type: 'AUTOCOMPLETE',
						//TODO
						autocomplete: 'vehiculeCompteur'
					},
					'VehiculeModele': {
						type: 'AUTOCOMPLETE',
						autocomplete: 'modele'
					},
					'VehiculeMarque': {
						type: 'AUTOCOMPLETE',
						autocomplete: 'marque'
					},
					'TypeCarrosserie': {
						type: 'AUTOCOMPLETE',
						autocomplete: 'typeCarrosserie'
					},
					'TypeBoite': {
						type: 'AUTOCOMPLETE',
						autocomplete: 'typeBoite'
					},
					'TypeTransmission': {
						type: 'AUTOCOMPLETE',
						autocomplete: 'typeTransmission'
					},
					'TypeFinancement': {
						type: 'AUTOCOMPLETE',
						autocomplete: 'typeFinancement'
					},
					'TypeSinistre': {
						type: 'AUTOCOMPLETE',
						autocomplete: 'typeSinistre'
					},
					'TypeResponsabilite': {
						type: 'ENUM',
						rootKey: 'vehicule.sinistre.typeResponsabilite',
						listeValues: ['PERCENT_0','PERCENT_50','PERCENT_100']
					},
					'TypeStatutSinistre': {
						type: 'ENUM',
						rootKey: 'vehicule.sinistre.statut',
						listeValues: ['NON_DEFINI','OUVERT','EN_COURS','FERME'],
					},
					'Vehicule': {
						type: 'AUTOCOMPLETE',
						autocomplete: 'vehicule'
					},
					'Financement': {
						type: 'AUTOCOMPLETE',
						autocomplete: 'contratFinancement'
					},
					'TypeEntretien': {
						type: 'ENUM',
						rootKey: 'entretien.type',
						listeValues: ['CONTROLE_REGLEMENTAIRE','ENTRETIEN','COURANT']
					},
					'TypeGenre': {
						type: 'ENUM',
						rootKey: 'vehicule.genre.type',
						listeValues: ['VEHICULE','ENGIN']
					},
					'TypeVehicule': {
						type: 'ENUM',
						rootKey: 'vehicule.typeVehicule',
						listeValues: ['VEHICULE_FONCTION_SERVICE','VEHICULE_PERSO','ENGIN']
					},
					'TypeCritAir': {
						type: 'ENUM',
						rootKey: 'vehicule.critAir',
						listeValues: ['AUCUN','CRITAIR_VERTE','CRITAIR_1','CRITAIR_2','CRITAIR_3','CRITAIR_4','CRITAIR_5','CRITAIR_NON_CLASSE']
					},
					'TypeEnergie': {
						type: 'ENUM',
						rootKey: 'vehicule.carburant.typeEnergie',
						listeValues: ['NON_DEFINI','BIODIESEL','DIESEL','ELECTRIQUE_HYDROGENE','ESSENCE','GAZ','HYBRIDE_RECHARGEABLE']
					},
					'TypeAmenagement': {
						type: 'AUTOCOMPLETE',
						autocomplete: 'typeAmenagement'
					},
					'TypeSource': {
						type: 'ENUM',
						rootKey: 'referentiel.typeReferentiel',
						listeValues: ['LOCAL','EXTERNE_CATALOGUE','EXTERNE_IMMATRICULATION']
					},
					'GrilleAttribution': {
						type: 'AUTOCOMPLETE',
						autocomplete: 'grilleAttribution',
						autocompleteFilter: 'true'
					},
					'Contravention': {
						type: 'AUTOCOMPLETE',
						autocomplete: 'contravention'
					},
					'TypeContravention': {
						type: 'ENUM',
						rootKey: 'infraction.contravention.type',
						listeValues: ['FORFAIT_POST_STATIONNEMENT','AMENDE']
					},
					'TypeStatutContravention': {
						type: 'ENUM',
						rootKey: 'infraction.contravention.statut',
						listeValues: ['IMPAYEE','PAYEE']
					},
					'TypeDesignation': {
						type: 'ENUM',
						rootKey: 'contravention.designation.type',
						listeValues: ['CONDUCTEUR']
					},
					'VehiculeReleveEnergie': {
						type: 'AUTOCOMPLETE',
						//TODO
						autocomplete: 'vehiculeReleveEnergie'
					},
					'TypeAnomalieLoyer': {
						type: 'ENUM',
						rootKey: 'contrat.typeAnomalieLoyer',
						listeValues: ['SANS_ECART','AVEC_ECART_IGNORE','AVEC_ECART']
					},
					'TypeOptionContrainte': {
						type: 'ENUM',
						rootKey: 'vehicule.modele.configuration.typeContrainte',
						listeValues: ['OBLIGATION','EXCLUSION','INCLUSION']
					},
					'TypeModeleOptionCategorie': {
						type: 'ENUM',
						rootKey: 'vehicule.modele.configuration.typeFamille',
						listeValues: ['ACCESSOIRE','CONDUITE','CONFORT','DESIGN','GARANTIE','MEDIA','PACK','PEINTURE','SECURITE']
					},
					'TypeModeleOption': {
						type: 'ENUM',
						rootKey: 'vehicule.modele.option.type',
						listeValues: ['AUCUNE','SERIE','GRATUITE','PAYANTE','PRIX_NON_COMMUNIQUE','OPTION_INDISPONIBLE']
					},
					'TypeVehiculeModeleConfiguration': {
						type: 'ENUM',
						rootKey: 'vehiculeModeleConfiguration.type',
						listeValues: ['VEHICULE_MODELE','VEHICULE','DEMANDE_VEHICULE','GRILLE_ATTRIBUTION']
					},
					'TypeAffectation': {
						type: 'ENUM',
						rootKey: 'vehicule.typeAffectation',
						listeValues: ['VEHICULE_SERVICE','VEHICULE_FONCTION','VEHICULE_IMMOBILISE','VEHICULE_POOL']
					},
					'TypeCalculAvantageNature': {
						type: 'ENUM',
						rootKey: 'vehicule.typeCalculAvantageNature',
						listeValues: ['ACHAT_AVEC_CARBURANT','ACHAT_SANS_CARBURANT','LOCATION_AVEC_CARBURANT','LOCATION_SANS_CARBURANT']
					},
					'TypeEtatFinancement': {
						type: 'ENUM',
						rootKey: 'vehicule.contrat.etat',
						listeValues: ['LOCATION','ACHAT']
					},
					'TypeAffichagePrix': {
						type: 'ENUM',
						rootKey: 'contrat.typeAffichagePrix.short',
						listeValues: ['TTC','HT']
					},
					'TypeRoueSecours': {
						type: 'ENUM',
						rootKey: 'vehicule.modele.pneumatique.typeRoueSecours',
						listeValues: ['AUCUNE','ROUE_SECOURS','GALETTE','KIT_REPARATION']
					},
					'TypeStatutPlanEntretienDetail': {
						type: 'ENUM',
						rootKey: 'entretien.intervention.statut',
						listeValues: ['A_REALISER','REALISE','REPORTE']
					},
					'sgi': {
						'TypeInfraction': {
							type: 'AUTOCOMPLETE',
							autocomplete: 'typeInfraction'
						},
						'TypeLot': {
							type: 'ENUM',
							rootKey: 'sgi.lot.typeLot',
							listeValues: ['ETAT_PARC','ACK_ETAT_PARC','DEMANDE_IDENTIFICATION','REPONSE_DESIGNATION','ACK_REPONSE_DESIGNATION']
						},
						'TypeFlux': {
							type: 'ENUM',
							rootKey: 'sgi.lot.typeFlux',
							listeValues: ['FULL','MAJ']
						},
						'TypeEtatSynchronisation': {
							type: 'ENUM',
							rootKey: 'sgi.typeEtatSynchronisation',
							listeValues: ['NON_DEFINI','SYNCHRONISE','NON_SYNCHRONISE']
						}
					},
					'cotation': {
						'DemandeCotation': {
							type: 'AUTOCOMPLETE',
							autocomplete: 'demandeCotation'
						},
						'ReponseCotation': {
							type: 'AUTOCOMPLETE',
							autocomplete: 'reponseCotation'
						},
						'TypeStatutDemandeCotation': {
							type: 'ENUM',
							rootKey: 'demandeCotation.statut',
							listeValues: ['NOUVELLE_DEMANDE','A_COMPLETER','A_TRANSMETTRE','RETOUR_COMPLET','RETOUR_PARTIEL','SOLDEE','EMISE_FOURNISSEUR','ANNULEE']
						},
						'TypeStatutReponseCotation': {
							type: 'ENUM',
							rootKey: 'reponseCotation.statut',
							listeValues: ['A_COMPLETER','A_TRANSMETTRE','TRANSMISE','RETENUE','NON_RETENUE','DECLINEE','ANNULEE']
						},
						'DemandeVehicule': {
							type: 'AUTOCOMPLETE',
							autocomplete: 'demandeVehicule'
						},
						'TypeStatutDemandeVehicule': {
							type: 'ENUM',
							rootKey: 'demandeVehicule.statut',
							listeValues: ['EN_COURS','REJETEE','EN_COMMANDE','LIVRE','A_VALIDER','VALIDEE','ANNULEE']
						}
					},
					'etatLieux': {
						'TypeStatut': {
							type: 'ENUM',
							rootKey: 'vehicule.etatLieux.statut',
							listeValues: ['EN_COURS','PLANIFIE','TERMINE']
						}
					},
					'pool': {
						'Reservation': {
							type: 'AUTOCOMPLETE',
							autocomplete: 'reservation'
						},
						'TypeStatutReservation': {
							type: 'ENUM',
							rootKey: 'reservation.statut',
							listeValues: ['EN_ATTENTE','VALIDEE','REJETEE','ANNULEE']
						}
					},
					'TypeEntretienItem': {
						type: 'AUTOCOMPLETE',
						autocomplete: 'typeEntretien'
					},
					'TypeUniteUsage': {
						type: 'ENUM',
						rootKey: 'vehicule.uniteUsage',
						listeValues: ['DISTANCE','TEMPS']
					},
					'TypeControleReglementaire': {
						type: 'ENUM',
						rootKey: 'vehicule.controleReglementaire.type',
						listeValues: ['TECHNIQUE','ANTIPOLLUTION']
					}
				},
				'fournisseur': {
					'Fournisseur': {
						type: 'AUTOCOMPLETE',
						autocomplete: 'fournisseur'
					},
					'FournisseurInterface': {
						type: 'AUTOCOMPLETE',
						autocomplete: 'fournisseurInterface'
					},
					'FournisseurNature': {
						type: 'AUTOCOMPLETE',
						autocomplete: 'fournisseurNature'
					},
					'Contact': {
						type: 'AUTOCOMPLETE',
						autocomplete: 'fournisseurContact'
					},
					'TypeFamilleNature': {
						type: 'ENUM',
						rootKey: 'nature.famille',
						listeValues: ['ENERGIE','ASSURANCE','ENTRETIEN','FRAIS_FINANCIER','PNEUMATIQUE','PARKING','PEAGE','TAXE','SINISTRE']
					},
					'TypeContact': {
						type: 'ENUM',
						rootKey: 'fournisseur.contact.type',
						listeValues: ['NON_DEFINI','CONTACT_1','CONTACT_2']
					},
					'TypeExecution': {
						type: 'ENUM',
						rootKey: 'fournisseur.formatInterface.modeExecution',
						listeValues: ['NON_DEFINI','MIDDLEWARE_BETA','MIDDLEWARE_STABLE']
					},
					'TypeField': {
						type: 'ENUM',
						rootKey: 'nature.elements.field.type',
						listeValues: ['RELEVE_COMPTEUR','RELEVE_ENERGIE','ENTRETIEN','REALISATION_CONTROLE_TECHNIQUE','REALISATION_CONTROLE_ANTIPOLLUTION']
					}
				},
				'service': {
					'Service': {
						type: 'AUTOCOMPLETE',
						autocomplete: 'service',
						listeOperators: ['EQUAL','NOT_EQUAL','IN','NOT_IN']
					},
					'TypeService': {
						type:'ENUM',
						rootKey: 'analytique.service.type',
						listeValues: ['SOCIETE','SERVICE']
					},
				},
				'sgi': {
					'DemandeIdentification': {
						type: 'AUTOCOMPLETE',
						autocomplete: 'demandeIdentification'
					},
					'TypeStatutIdentification': {
						type: 'ENUM',
						rootKey: 'demandeIdentification.statut',
						listeValues: ['NOUVELLE_DEMANDE','ANOMALIE','A_TRANSMETTRE','TRANSMIS','INTEGREE','ENVOI_PAPIER']
					},
					'TypeDesignation': {
						type: 'ENUM',
						rootKey: 'demandeIdentification.typeDesignation',
						listeValues: ['CONDUCTEUR','VEHICULE_VENDU','VEHICULE_VOLE','VEHICULE_RENDU','ENVOI_PAPIER','SOCIETE']
					},
					'TypeReponseConducteur': {
						type: 'ENUM',
						rootKey: 'demandeIdentification.typeReponseConducteur',
						listeValues: ['NON_DEFINI','AUTODESIGNATION','REJET_AUTODESIGNATION']
					}
				},
				'facture': {
					'Facture': {
						type: 'AUTOCOMPLETE',
						autocomplete: 'facture'
					},
					'TypeFacture': {
						type: 'ENUM',
						rootKey: 'facture.type',
						listeValues: ['FACTURE','AVOIR']
					},
					'TypeStatutFacture': {
						type: 'ENUM',
						rootKey: 'facture.statut',
						listeValues: ['INTEGREE','VALIDEE','COMPTABILISEE']
					},
					'TypeStatutDetail': {
						type: 'ENUM',
						rootKey: 'facture.detail.statut',
						listeValues: ['INTEGREE','REJETEE','VALIDEE','COMPTABILISEE']
					},
					'TypeEtat': {
						type: 'ENUM',
						rootKey: 'facture.etat',
						listeValues: ['BROUILLON','VALIDE']
					},
					'TypeOrigine': {
						type: 'ENUM',
						rootKey: 'facture.origine',
						listeValues: ['SAISIE','IMPORT','LAD']
					}
				},
				'equipement': {
					'TypeEquipement': {
						type: 'ENUM',
						rootKey: 'equipement.type',
						listeValues: ['AVERTISSEUR_RADAR','CARTE_CARBURANT','GEOLOCALISATION','TELEMATIQUE','TELEPEAGE','CARTE_ENTRETIEN','CARTE_ENERGIE']
					},
					'TypeStatutEquipement': {
						type: 'ENUM',
						rootKey: 'equipement.statut',
						listeValues: ['EN_COMMANDE','ACTIF','ANNULE','OPPOSE','CLOTURE']
					},
					'Equipement': {
						type: 'AUTOCOMPLETE',
						autocomplete: 'equipement'
					},
					'TypeAffectation': {
						type: 'ENUM',
						rootKey: 'equipement.typeAffectation',
						listeValues: ['VEHICULE','COLLABORATEUR']
					}
				},
				'groupe': {
					'Groupe': {
						type: 'AUTOCOMPLETE',
						autocomplete: 'groupe'
					}
				},
				'financement': {
					'Financement': {
						type: 'AUTOCOMPLETE',
						autocomplete: 'contratFinancement'
					}
				},
				'chart': {
					'TypeDiffusion': {
						type: 'ENUM',
						rootKey: 'chart.typeDiffusion',
						listeValues: ['TOUS','REVENDEUR','CLIENT','ROOT']
					}
				},
				'comptabilite': {
					'TypeLienCompte': {
						type: 'ENUM',
						rootKey: 'comptabilite.compte.typeCharge',
						listeValues: ['NATURE','USER','FOURNISSEUR']
					},
					'TypeCompte': {
						type: 'ENUM',
						rootKey: 'comptabilite.compte.type',
						listeValues: ['BANQUE','CHARGE','TIERS','TAXE']
					},
					'PlanComptable': {
						type: 'AUTOCOMPLETE',
						autocomplete: 'planComptable'
					},
					'Compte': {
						type: 'AUTOCOMPLETE',
						autocomplete: 'compte'
					}
				},
				'customRef': {
					'TypeCustomRef': {
						type: 'ENUM',
						rootKey: 'customRef.type',
						listeValues: ['REFERENTIEL_1','REFERENTIEL_2','REFERENTIEL_3','REFERENTIEL_4']
					},
					'CustomRefValue': {
						type: 'AUTOCOMPLETE',
						autocomplete: 'customReferentiel'
					}
				},
				'fiscalite': {
					'TypeRegleTaxe': {
						type: 'ENUM',
						rootKey: 'fiscalite.regleTaxe.type',
						listeValues: ['DATE','DATE_GENRE']
					}
				},
				'time': {
					'TypeJour': {
						type: 'ENUM',
						rootKey: 'scheduler.jours',
						listeValues: ['LUNDI','MARDI','MERCREDI','JEUDI','VENDREDI','SAMEDI','DIMANCHE']
					}
				},
				'profil': {
					'TypeRestriction': {
						type: 'ENUM',
						rootKey: 'profil.typeRestriction',
						listeValues: ['AUCUNE','SOCIETE','SERVICE','VEHICULE_POOL']
					},
					'TypeRestrictionDynamique': {
						type: 'ENUM',
						rootKey: 'profil.typeRestrictionDynamique',
						listeValues: ['RESPONSABLE_1','RESPONSABLE_2','RESPONSABLE_3','RESPONSABLE_4','ANY']
					}
				},
				'todoList': {
					'TypeStatutTodoListItem': {
						type: 'ENUM',
						rootKey: 'todoList.typeStatut',
						listeValues: ['TODO','DONE']
					}
				},
				'licence': {
					'TypeUsageLicence': {
						type: 'ENUM',
						rootKey: 'licence.usage.type',
						listeValues: ['VEHICULE','FINANCEMENT','VEHICULE_COMPTEUR','SINISTRE','CONTRAVENTION','GRILLE_ATTRIBUTION','AMENAGEMENT','FACTURE','TO_DO_LIST','CONNECTEUR_FACTURE','ENTRETIEN','ANTAI','DEMANDE_VEHICULE','DEMANDE_VEHICULE_WORKFLOW','LOT_COMPTABLE','DEMANDE_COTATION','REPONSE_COTATION']
					},
					'TypeConsommationLicence': {
						type: 'ENUM',
						rootKey: 'licence.consommation.type',
						listeValues: ['VEHICULE','ANTAI']
					}
				},
				'connecteur': {
					'TypeConnecteur': {
						type: 'ENUM',
						rootKey: 'connecteur.type',
						listeValues: ['API','INTERFACE']
					},
					'TypeExecution': {
						type: 'ENUM',
						rootKey: 'connecteur.typeExecution',
						listeValues: ['NON_DEFINI','INTEGRATION','MIDDLEWARE']
					},
					'TypeEnvironnement': {
						type: 'ENUM',
						rootKey: 'connecteur.environnement',
						listeValues: ['DEFAULT','BETA']
					}
				},
				'grilleFluidite': {
					'GrilleFluidite$TypeObjet': {
						type: 'ENUM',
						rootKey: 'grilleFluidite.type',
						listeValues: ['CONTRAT_FINANCEMENT','DEMANDE_VEHICULE','GRILLE_ATTRIBUTION_MODELE_FINANCEMENT']
					}
				}
			},
			'api': {
				'data': {
					'model': {
						'DataResult$Statut': {
							rootKey: 'dataResult.statut',
							type: 'ENUM',
							listeValues: ['FINISHED','PENDING','NOT_STARTED','FAILED']
						}
					}
				}
			},
			'utils': {
				'TimeZone': {
					type: 'ENUM',
					rootKey: 'timezone.type',
					listeValues: ['EUROPE_PARIS']
				}
			}
		}
	}
}