import { Session } from 'src/app/domain/security/session';
import { Action } from 'src/app/domain/action';

/**
 * Export des actions
 */
export const SESSION_FULFILLED = 'SESSION_FULFILLED';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_ORIGINE = 'UPDATE_USER_ORIGINE';
export const UPDATE_SAVED_PATH = 'UPDATE_SAVED_PATH';
export const LOAD_SESSION_USER = 'LOAD_SESSION_USER';
export const SESSION_LOGOUT_TO_ORIGINAL_USER = 'SESSION_LOGOUT_TO_ORIGINAL_USER';
export const CHECK_USER_ARTICLE = 'CHECK_USER_ARTICLE';
export const UPDATE_USER_CONSENTEMENT = 'UPDATE_USER_CONSENTEMENT';
export const UPDATE_APPLI_URL = 'UPDATE_APPLI_URL';
export const UPDATE_CONFIDENTIALITE_URL = 'UPDATE_CONFIDENTIALITE_URL';
export const UPDATE_SUPPORT_URL = 'UPDATE_SUPPORT_URL';

/**
 * Création du reducer
 */
export function sessionReducer(state: Session = new Session(),action: Action): Session {
	//Vérification de l'action
	switch (action.type) {
	case SESSION_FULFILLED:
		//Session de l'utilisateur
		state = {
			...state,
			...(action.payload.isRefresh ? { xAuthToken: action.payload.xAuthToken } : action.payload)
		};
		break;
	case UPDATE_USER:
		//Mise à jour de l'utilisateur
		state = {
			...state,
			user: action.payload,
			codeTenant: action.payload?.tenant?.code || null,
			originalCodeTenant: action.payload?.originalUser?.tenant?.code || null
		};
		break;
	case UPDATE_USER_ORIGINE:
		//Mise à jour de l'utilisateur d'origine
		state = {
			...state,
			userOrigine: action.payload
		};
		break;
	case UPDATE_SAVED_PATH:
		//Mise à jour de la route mémorisée le temps de s'authentifier
		state = {
			...state,
			savedPath: action.payload?.savedPath
		};
		break;
	case UPDATE_USER_CONSENTEMENT:
		//Mise à jour du consentement de l'utilisateur
		state = {
			...state,
			user: {
				...state.user,
				consentementNeeded: action.payload
			}
		};
		break;
	case UPDATE_APPLI_URL:
		//Mise à jour de l'URL de l'application
		state = {
			...state,
			appliUrl: action.payload
		};
		break;
	case UPDATE_CONFIDENTIALITE_URL:
		//Mise à jour de l'URL de confidentialité
		state = {
			...state,
			confidentialiteUrl: action.payload
		};
		break;
	case UPDATE_SUPPORT_URL:
		//Mise à jour de l'URL du support
		state = {
			...state,
			supportUrl: action.payload
		};
		break;
	}

	//Retour de l'état
	return state;
}