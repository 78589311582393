import { AfterContentChecked,ChangeDetectorRef,Component,EventEmitter,Input,OnDestroy,OnInit,Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { cloneDeep } from 'lodash-es';
import { ControlContainer,NgForm } from '@angular/forms';

import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { MessagingObservables } from 'src/app/domain/messaging/messaging-observables';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ConnecteurService } from 'src/app/components/connecteur/connecteur.service';
import { ImportService } from 'src/app/components/connecteur/import/import.service';

@Component({
	selector: 'import-content',
	templateUrl: './import-content.component.html',
	viewProviders: [{
		provide: ControlContainer,
		useExisting: NgForm
	}]
})
export class ImportContentComponent implements OnInit,OnDestroy,AfterContentChecked {
	/** Elément courant **/
	@Input() connecteurExecution: any;

	/** Source **/
	@Input() source: 'IMPORT' | 'MODAL';

	/** Fermeture du formulaire **/
	@Output() close = new EventEmitter<any>();

	/** Rafraichissement du formulaire **/
	@Output() update = new EventEmitter<any>();

	/** Enumération des types d'attachment **/
	public TypeAttachment: typeof TypeAttachment = TypeAttachment;

	/** Progression du traitement **/
	progress: { nbProcessed: number,nbTotal: number,connecteurExecution: any } = null;

	/** Connexion à la WebSocket **/
	messaging$: MessagingObservables;

	/**
	 * Constructeur
	 */
	constructor(private importService: ImportService,private toastrService: ToastrService,private translateService: TranslateService,private confirmService: ConfirmService,private changeDetectorRef: ChangeDetectorRef,private connecteurService: ConnecteurService,public rightService: RightService) { }

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Sauvegarde des objets
		this.connecteurExecution = cloneDeep(this.connecteurExecution);

		//Vérification de la session de traitement
		if (this.source == 'MODAL' || this.connecteurExecution?.apiSession && this.connecteurExecution?.apiSession?.statut != 'FINISHED') {
			//Ecoute de la progression
			const { messaging$,subject } = this.importService.retrieveProgress(this.connecteurExecution);

			//Mémorisation de la WebSocket
			this.messaging$ = messaging$;

			//Souscription à la progression
			subject.subscribe({
				next: (progress: any) => {
					//Vérification de la progression
					if (this.progress) {
						//Mise à jour de la progression
						this.progress.nbProcessed = progress.nbProcessed || this.progress.nbProcessed;
						this.progress.nbTotal = progress.nbTotal || this.progress.nbTotal;
					} else
						//Définition de la progression
						this.progress = progress;

					//Vérification de l'exécution
					if (progress.connecteurExecution)
						//Mise à jour de l'exécution
						this.update.emit(Object.assign(this.connecteurExecution,progress.connecteurExecution));
				}
			})
		}
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy() {
		//Fermeture de la connexion
		this.messaging$?.close();
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Suppression de l'import
	 */
	deleteImport() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression de l'import
					this.importService.deleteImport(this.connecteurExecution).subscribe({
						next: result => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression de l'import
								this.close.emit(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Consultation des logs
	 */
	showLogs() {
		//Vérification de l'historique
		if (this.connecteurExecution?.listeExecutionHistoricals?.length) {
			//Vérification de l'exécution
			if (this.connecteurExecution.apiSession != null && this.connecteurExecution.correlationId != null && !this.connecteurExecution.listeExecutionHistoricals.some(e => e.correlationId == this.connecteurExecution.correlationId)) {
				//Ajout de l'exécution courante
				this.connecteurExecution.listeExecutionHistoricals.push({
					apiSession: this.connecteurExecution.apiSession,
					correlationId: this.connecteurExecution.correlationId
				});
			}

			//Affichage de l'historique d'exécution
			this.connecteurService.showExecutionHistory(this.connecteurExecution);
		} else
			//Affichage du résultat de l'exécution
			this.connecteurService.showExecutionResult(this.connecteurExecution);
	}
}