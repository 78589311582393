<app-header></app-header>
<app-menu></app-menu>

<div class="clearfix"></div>
<div class="view" [ngClass]="{ 'toggled': isMenuOpened }">
	<page-title></page-title>
	<div>
		<router-outlet (activate)="onActivate($event)"></router-outlet>
	</div>
</div>

<todo-list *ngIf="session.isLogged"></todo-list>
<floating-button *ngIf="session?.isLogged && session.user?.type != 'VIRTUAL'" defaultIcon="blur_on" [isAlwaysShown]="true" [isGlobal]="true" (click)="!chatbotService.isDisplayed() && chatbotService.toggleDisplay()"></floating-button>
<chatbot *ngIf="session?.isLogged && chatbotService.isDisplayed()"></chatbot>